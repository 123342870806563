@font-face {
  font-family: "Inter";
  src: url("03d72f0a6e35ca5a");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("bca246d008e04199");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("14351bf220584280");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "RF Rostin";
  src: url("36c43afe903e06ad");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "RF Rostin";
  src: url("d649edd5b00a809f");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "RF Rostin";
  src: url("cec75286a91a8183");
  font-weight: 400;
  font-style: normal;
}
body {
  font-family: "RF Rostin", Arial, sans-serif;
  color: #4520ab;
  min-height: 100vh;
  margin: 0;
  background: #f0f0f0;
}
form input[type="text"],
form input[type="email"],
form input[type="tel"],
form input[type="number"] {
  background-color: #eee;
  color: #4520ab;
  padding: 10px;
  border: 0;
  max-width: 251px !important;
  border: none !important;
  padding: 12px !important;
  border-radius: 6px;
  border: 0;
  border-bottom: 2px solid #4520ab;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 20px;
  font-family: "Inter", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  opacity: 0.9;
}
@media (max-width: 535px) {
  form input[type="text"],
  form input[type="email"],
  form input[type="tel"],
  form input[type="number"] {
    max-width: 100% !important;
    margin-left: 0;
  }
}
form input[type="text"]::placeholder,
form input[type="email"]::placeholder,
form input[type="tel"]::placeholder,
form input[type="number"]::placeholder {
  color: #4520ab;
}
form input[type="text"]:hover,
form input[type="email"]:hover,
form input[type="tel"]:hover,
form input[type="number"]:hover {
  opacity: 1;
}
form input[type="text"]:focus,
form input[type="email"]:focus,
form input[type="tel"]:focus,
form input[type="number"]:focus,
form input[type="text"]:active,
form input[type="email"]:active,
form input[type="tel"]:active,
form input[type="number"]:active {
  opacity: 1;
  outline: none;
}
form input[type="text"]:required:not(:focus):not(:placeholder-shown):invalid,
form input[type="email"]:required:not(:focus):not(:placeholder-shown):invalid,
form input[type="tel"]:required:not(:focus):not(:placeholder-shown):invalid,
form input[type="number"]:required:not(:focus):not(:placeholder-shown):invalid {
  border-bottom-color: #f00;
}
form legend {
  font-weight: bold;
  width: 100%;
  color: #333;
  padding: 3px 5px 15px 0px;
}
form :not(:first-of-type) {
  margin-left: 20px;
}
form.pay-form,
.page-block {
  user-select: none;
  border-radius: 3px;
  background: #fff;
  margin: 100px auto;
  max-width: 767px;
  min-width: 320px;
  padding: 34px 41px;
  box-shadow: 0 0 15px 0px rgba(0,0,0,0.1);
}
@media (max-width: 500px) {
  form.pay-form,
  .page-block {
    padding: 14px 20px;
  }
}
@media (max-width: 380px) {
  form.pay-form,
  .page-block {
    margin: 0;
  }
}
form.pay-form h1,
.page-block h1 {
  font-size: 18px;
  color: #333;
  margin: 0 0 20px 0;
}
form.pay-form fieldset.document,
.page-block fieldset.document {
  width: 100%;
  align-self: flex-end;
  margin-top: 12px;
}
form.pay-form fieldset.document input[type="checkbox"],
.page-block fieldset.document input[type="checkbox"] {
  cursor: pointer;
  position: relative;
  top: 1px;
}
form.pay-form fieldset.type,
.page-block fieldset.type {
  margin-bottom: 20px;
}
form.pay-form fieldset.type label,
.page-block fieldset.type label {
  cursor: pointer;
  display: block;
  margin: 0px 10px 10px 10px;
}
form.pay-form fieldset.type label:hover,
.page-block fieldset.type label:hover {
  opacity: 0.9;
}
form.pay-form fieldset,
.page-block fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
form.pay-form fieldset input,
.page-block fieldset input {
  max-width: 50%;
}
form.pay-form .of-course,
.page-block .of-course {
  color: #4520ab;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  font-weight: normal;
  font-family: Arial;
}
form.pay-form .footer,
.page-block .footer {
  display: flex;
}
@media (max-width: 700px) {
  form.pay-form .footer,
  .page-block .footer {
    flex-direction: column-reverse;
  }
}
form.pay-form .footer .price,
.page-block .footer .price {
  color: #ffbc5b;
  font-size: 21px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 18px;
  font-weight: 700;
  font-size: 32px;
}
@media (max-width: 560px) {
  form.pay-form .footer .price,
  .page-block .footer .price {
    font-size: 22px;
    line-height: 24px;
    max-width: 161px;
  }
}
form.pay-form .footer:after,
.page-block .footer:after {
  content: "";
  display: block;
  clear: both;
}
.page-block h1 {
  text-align: center;
  margin-top: 20px;
}
.page-block svg {
  display: block;
  margin: 30px auto;
  width: 100px;
  fill: #70af25;
}
.page-block.fail svg {
  fill: #ea3c3c;
}
.program-main {
  color: #4520ab;
  font-weight: 700;
  font-size: 28px;
  margin-right: 20px;
}
@media (max-width: 560px) {
  .program-main {
    font-size: 20px;
  }
}
@media (max-width: 430px) {
  .program-main {
    font-size: 18px;
    margin-right: 10px;
  }
}
.program-light {
  color: rgba(69,32,171,0.5);
  font-size: 28px;
  font-weight: 300;
}
@media (max-width: 560px) {
  .program-light {
    font-size: 20px;
  }
}
@media (max-width: 430px) {
  .program-light {
    font-size: 18px;
  }
}
.input-promocode {
  margin-right: 20px;
}
@media (max-width: 535px) {
  .input-promocode {
    max-width: 70% !important;
  }
}
.submit-button {
  min-height: 44px;
  border-radius: 6px;
  color: #4520ab;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  padding: 4px 30px;
  margin-right: 5px;
  text-align: center;
  color: #4730ab;
  font-size: 18px;
  font-family: "RF Rostin", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 700;
  letter-spacing: 1px;
  border-width: 0px;
  background-color: #faba5a;
  box-shadow: 0px 6px 39px 0px rgba(255,188,91,0.3);
}
@media (max-width: 560px) {
  .submit-button {
    font-size: 11px;
    padding: 0 15px !important;
    margin: 0;
    line-height: 20px;
    max-width: 141px;
  }
}
.submit-button:focus,
.submit-button:active {
  transform: scale(0.97);
  outline: none;
}
.submit-button:hover {
  opacity: 0.94;
}
.outline-button {
  min-height: 44px;
  border-radius: 6px;
  color: #4520ab;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  padding: 4px 30px;
  margin-right: 5px;
  text-align: center;
  color: #0f0f0f;
  font-size: 18px;
  font-family: "RF Rostin", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 500;
  letter-spacing: 1px;
  border: 2px solid #0f0f0f;
  background-color: #fff;
  box-shadow: 0px 6px 39px 0px rgba(188,188,188,0.3);
}
@media (max-width: 560px) {
  .outline-button {
    font-size: 11px;
    padding: 0 15px !important;
    margin: 0;
    line-height: 20px;
    max-width: 141px;
  }
}
.outline-button:focus,
.outline-button:active {
  transform: scale(0.97);
  outline: none;
}
.outline-button:hover {
  opacity: 0.94;
}
.inputs-container {
  display: flex;
}
@media (max-width: 430px) {
  .inputs-container {
    flex-direction: column;
  }
}
.promocode {
  display: none;
}
.promocode-input-container {
  display: flex;
  width: auto !important;
}
.input-container {
  width: 100%;
  max-width: 275px !important;
  margin-right: 20px;
}
@media (max-width: 430px) {
  .input-container {
    flex-direction: column;
  }
}
.error-helper {
  display: none;
  color: #ff6363;
  font-family: RF Rostin;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 768px) {
  .error-helper {
    font-size: 9px;
  }
}
.button-container {
  max-width: 462px;
}
.select-container {
  display: flex;
  align-items: center;
}
div.logo {
  display: flex;
  width: 108px;
  height: 108px;
  margin-right: 52px;
}
@media (max-width: 560px) {
  div.logo {
    margin-right: 22px;
  }
}
@media (max-width: 430px) {
  div.logo {
    margin-right: 0;
  }
}
div.logo.spb {
  background-image: url("fde2f7189533ed3d");
  background-size: 108px 108px;
}
@media (max-width: 480px) {
  div.logo.spb {
    background-size: 46px 46px;
  }
}
div.logo.kzn {
  background-image: url("a30ab032b2db5dfe");
  background-size: 108px 108px;
}
@media (max-width: 480px) {
  div.logo.kzn {
    background-size: 46px 46px;
  }
}
div.logo.online {
  background-image: url("878538ad7d945430");
  background-size: 108px 108px;
}
@media (max-width: 480px) {
  div.logo.online {
    background-size: 46px 46px;
  }
}
div.logo.msk,
div.logo.js {
  background-image: url("6a30671225a45fc8");
  background-size: 108px 108px;
}
@media (max-width: 480px) {
  div.logo.msk,
  div.logo.js {
    background-size: 46px 46px;
  }
}
div.logo.english {
  background-image: url("6a30671225a45fc8");
  background-size: 108px 108px;
}
@media (max-width: 480px) {
  div.logo.english {
    background-size: 46px 46px;
  }
}
div.logo.ds {
  background-image: url("a6013a31d6035cf0");
  background-size: 108px 108px;
}
@media (max-width: 480px) {
  div.logo.ds {
    background-size: 46px 46px;
  }
}
.program-name {
  font-weight: 700;
  font-size: 18px;
}
@media (max-width: 560px) {
  .program-name {
    font-size: 16px;
    font-weight: 600;
  }
}
@media (max-width: 520px) {
  .program-name {
    font-size: 14px;
  }
}
.courses-name {
  padding: 10px 30px 10px 10px;
  border-radius: 8px;
  appearance: none;
  color: #4520ab;
  background: url("22dda3b12e5415cd") no-repeat right;
  background-position-x: calc(100% - 10px);
  background-size: 20px 8px;
}
@media (max-width: 768px) {
  .courses-name .select {
    width: 100%;
    font-size: 14px;
  }
}
.agree,
agree-light,
.promocode-info {
  font-size: 11px;
  font-family: Inter;
}
@media (max-width: 768px) {
  .agree,
  agree-light,
  .promocode-info {
    font-size: 9px;
  }
}
.agree-light {
  color: rgba(69,32,171,0.5) !important;
}
.agree-link {
  text-decoration: underline;
  color: inherit;
}
.agree-link:hover {
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.2s;
}
[data-mobile-logo] {
  display: none !important;
  width: 46px !important;
  height: 46px !important;
}
@media (max-width: 480px) {
  [data-mobile-logo] {
    display: inline-block !important;
  }
}
@media (max-width: 480px) {
  [data-desktop-logo] {
    display: none !important;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-container {
  margin-right: 32px;
  max-width: 251px;
}
@media (max-width: 700px) {
  .price-container {
    display: flex;
    max-width: 100%;
    margin-right: 0px;
    justify-content: space-between;
    margin-top: 20px;
  }
}
